import {useWatchList, useWatchListDispatch} from "./WatchListContext";
import {HitViewShort} from "./HitViewShort";
import {useContext} from "react";
import LanguageContext from "./LanguageContext";
import FieldLabels from "./FieldLabels";
import styled from "styled-components";

const StyledWatchlist= styled("div") `

@page
{
    size: Letter portrait;
    margin: 0;
}

@media print
{
    html,
    body
    {
        background-color: white !important;
    }
    .hit
    {
        background-color: white;
        padding: 0in;
        width: 8.5in;
        height: 11in;
        /* For centering the page on the screen during preparation */
        margin: 8px auto;
        page-break-inside:avoid; 
    }
    header, .navigation
    {
        display: none;
    }
}
`;
export default function WatchList({config,navigation}) {
  const lang = useContext(LanguageContext)
  const items = useWatchList();
  const dispatch = useWatchListDispatch();
  let message;
  if(Object.keys(items).length===0){
    message=FieldLabels.watchlistIsEmpty[lang];
  }
  return (
      <StyledWatchlist><div className={"sui-layout"}>
      <div className={"sui-layout-header"}>
      <div className={"sui-layout-header__inner"}>
      <config.vitrineHeader />
        <p className={"navigation"}>&lt;&lt;  {navigation}</p>
      </div>
      </div>

      </div>
      <div className={"sui-layout-body"}>

      <div className={"sui-layout-body-body"}>

        <div className={"sui-layout-body__inner"}>

          <div className={"sui-layout-sidebar"}>

            <p>
            <a href={"#"} onClick={(e)=>window.print()}> <i className={"fa-solid fa-print"}/> {FieldLabels.printWatchlist[lang]} </a>
            </p>
            <p>
            <a href={"#"} onClick={() => {
              items.forEach((item)=> {
                dispatch({
                  type: 'deleted',
                  doi: item.doi,
                  prefix: item.prefix
                });
              });

            }}> <i className={"fa-solid fa-trash"}/> {FieldLabels.emptyWatchlist[lang]} </a>
            </p>
          </div>

          <div className={"sui-layout-main"}>

            <div className={"sui-layout-main-header"} id={"Merkliste"}>

              <div className={"sui-layout-main-header__inner"}>



              </div>
            </div>
        <div>

          <div>
         <p>{message}</p>
          </div>
            <ul>

          {items.map(item => (
            <li className='sui-result hit container ' key={item.doi}>
            <Item item={item}/>
            </li>
        ))}
        </ul>
        </div>
        </div>
      </div>
      </div>
      </div>
      </StyledWatchlist>
  );
}

export function Item({ item }) {
 return ( <><HitViewShort result={item.result} /></>);
}

