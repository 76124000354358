import React, { Fragment } from 'react'

/* eslint  no-console: 0 */
class VitrineFooter extends React.Component {
  render() {
    return (
      <Fragment>
        <div id='mastfooter' className='site-footer'>
          <div className='site-footer-bg bg-red-gradient'>
            <div id='footer-container' className='container-fluid'>
              <div className='row'>
                <div className='footer-col footer-col-left col-12 col-sm-6 col-md-55 col-lg-4 order-1'>
                  <div id='footer-logo' className='footer-logo'>
                    <svg
                      version='1.1'
                      className='icon icon-logo icon-logo-footer'
                      x='0px'
                      y='0px'
                      width='288.386px'
                      height='122px'
                      viewBox='0 0 288.386 122'
                      enableBackground='new 0 0 288.386 122'
                    >
                      <g transform='translate(0.000000, 32.000000)'>
                        <polygon className='st0' points='144,90 148,90 148,-32 144,-32 	'></polygon>
                        <path
                          className='st0'
                          d='M43.28,35.542c-0.727,1.99-1.813,3.739-3.242,5.235c-1.435,1.506-3.248,2.676-5.439,3.521
            c-2.192,0.847-4.717,1.271-7.585,1.271H13.791V12.843v-0.005h13.223c0.179,0,0.358,0,0.533,0.005
            c2.649,0.051,4.985,0.488,7.013,1.305c2.162,0.872,3.974,2.061,5.434,3.557c1.464,1.502,2.56,3.236,3.287,5.195
            c0.482,1.301,0.807,2.661,0.966,4.082c0.089,0.726,0.129,1.466,0.129,2.222C44.376,31.441,44.012,33.551,43.28,35.542
             M56.424,18.033c-0.767-1.849-1.713-3.583-2.844-5.19c-0.981-1.406-2.101-2.711-3.362-3.931c-2.704-2.62-5.961-4.665-9.776-6.137
            c-3.815-1.476-8.153-2.212-13.02-2.212H0v11.151v1.128v44.995h27.422c4.812,0,9.155-0.736,13.02-2.207
            c3.865-1.477,7.142-3.507,9.816-6.097c2.68-2.59,4.732-5.618,6.166-9.085c1.434-3.461,2.151-7.209,2.151-11.246
            c0-0.75-0.024-1.491-0.074-2.222C58.282,23.827,57.589,20.844,56.424,18.033'
                        ></path>
                        <polygon
                          className='st0'
                          points='179.58,34.357 208.463,34.357 208.463,23.066 179.58,23.066 179.58,12.43
            212.113,12.43 212.113,0.563 166.192,0.563 166.192,57.838 212.925,57.838 212.925,45.977 179.58,45.977 	'
                        ></polygon>
                        <defs>
                          <filter
                            id='Adobe_OpacityMaskFilter'
                            filterUnits='userSpaceOnUse'
                            x='224.621'
                            y='0.563'
                            width='63.765'
                            height='57.274'
                          >
                            <feColorMatrix type='matrix' values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0'></feColorMatrix>
                          </filter>
                        </defs>
                        <polygon
                          className='st0'
                          points='265.067,29.415 266.96,26.981 277.951,12.843 287.494,0.563
            270.859,0.563 261.86,12.843 257.068,19.388 251.934,12.843 242.307,0.563 225.024,0.563 235.051,12.843 246.595,26.981
            247.83,28.498 224.621,57.838 241.251,57.838 255.938,38.641 271.104,57.838 288.386,57.838 	'
                        ></polygon>
                        <path
                          className='st0'
                          d='M114.353,42.752c-3.083,1.744-6.575,2.62-10.469,2.62h-3.332l8.292,12.466h15.903
            l-10.24-15.176C114.457,42.691,114.407,42.723,114.353,42.752'
                        ></path>
                        <path
                          className='st0'
                          d='M117.953,29.49c0.493-0.806,0.917-1.643,1.27-2.509c0.966-2.333,1.45-4.893,1.45-7.679
            c0-2.303-0.364-4.455-1.101-6.46c-0.388-1.083-0.892-2.117-1.499-3.114c-1.728-2.836-4.07-5.074-7.018-6.711
            c-2.949-1.632-6.286-2.454-10.016-2.454H70.942v12.279H84.49v-0.005h13.382c0.064,0,0.129,0,0.194,0.005
            c1.494,0.02,2.849,0.292,4.068,0.811c1.271,0.549,2.281,1.311,3.039,2.292c0.757,0.982,1.135,2.182,1.135,3.603
            c0,1.417-0.378,2.686-1.135,3.805c-0.757,1.119-1.768,2.005-3.039,2.655c-1.17,0.599-2.46,0.927-3.874,0.973
            c-0.13,0.01-0.259,0.01-0.389,0.01H84.49v-0.01H70.942v30.856h13.791V39.185h15.41c3.894,0,7.386-0.872,10.468-2.62
            C113.695,34.821,116.14,32.463,117.953,29.49'
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <div className='footer-subcontainer company-container'>
                    Das Datenportal <br />
                    für Rassismus- und <br />
                    Rechtsextremismus forschung
                  </div>
                </div>

                <div className='footer-col footer-col-med col-12 col-lg-4 order-2 order-sm-3 order-md-4 order-lg-2'>
                  <nav id='secondarynav' className='menu-container secondary-menu-container' role='navigation' aria-label='Secondary'>
                    <ul className='secondary-menu menu'>
                      <li
                        id='menu-item-40'
                        className='menu-item menu-item-type-post_type menu-item-object-page menu-item-40 ct-secondary__link'
                      >
                        <a href='https://datenportal-rechtsextremismus.de/impressum/'>Impressum &amp; Kontakt</a>
                      </li>
                      <li
                        id='menu-item-41'
                        className='menu-item menu-item-type-post_type menu-item-object-page menu-item-41 ct-secondary__link'
                      >
                        <a href='https://datenportal-rechtsextremismus.de/faq/'>FAQ</a>
                      </li>
                      <li
                        id='menu-item-39'
                        className='menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-39 ct-secondary__link'
                      >
                        <a rel='privacy-policy' href='https://datenportal-rechtsextremismus.de/datenschutzerklaerung/'>
                          Datenschutz
                        </a>
                      </li>
                      <li
                        id='menu-item-42'
                        className='menu-item menu-item-type-post_type menu-item-object-page menu-item-42 ct-secondary__link'
                      >
                        <a href='https://datenportal-rechtsextremismus.de/barrierefreiheit/'>Barrierefreiheit</a>
                      </li>
                      <li
                        id='menu-item-43'
                        className='menu-item menu-item-type-post_type menu-item-object-page menu-item-43 ct-secondary__link'
                      >
                        <a href='https://datenportal-rechtsextremismus.de/rueckmeldung-zur-barrierefreiheit/'>BITV-Feedback</a>
                      </li>
                    </ul>
                  </nav>
                </div>

                <div className='footer-col footer-col-empty col-0 col-sm-0 col-md-1 col-lg-0 order-4 order-sm-4 order-md-2 order-lg-4'></div>

                <div className='footer-col footer-col-right col-12 col-sm-6 col-md-55 col-lg-4 order-3 order-sm-2 order-md-3 order-lg-3'>
                  <div className='footer-subcontainer bundeslogo-container'>
                    <div id='footer-logo2' className='footer-logo2'>
                      <a
                        className='extlink'
                        href='https://www.bmbf.de/bmbf/de/home/home_node.html'
                        title=''
                        target='_blank'
                        rel='noreferrer noopener'
                      >
                        <div className='logo-img-container'>
                          <img src='https://datenportal-rechtsextremismus.de/wp-content/uploads/2023/05/bundes-logo.png' alt='' title='' />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default VitrineFooter
