import React, { Fragment } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

/* eslint  no-console: 0 */
class VitrineHeader extends React.Component {
  render() {
    return (
        <Fragment>
          <header id="site-header" className="header-footer-group" role="banner">
            <div className="header-inner section-inner">
              <div className="container-fluid">
                <div className="row" id="topnav-main-row">
                  <div id="mainlogo"
                       className="mainlogo ct-header__mainlogo site-logo col-8 col-lg-4 order-1 order-lg-1">
                    <a className="mainlogo-link logo-link"
                       href="https://www.diw.de/">

                      <img id="logo" src={"./logos/diw_logo.svg"}           /></a></div>
                  <div className="col-hamburger col-4 col-lg-0 order-2 order-lg-3"><span
                      className="toggle nav-toggle mobile-nav-toggle"
                      data-toggle-target=".menu-modal" data-toggle-body-class="showing-menu-modal"
                      aria-expanded="false" data-set-focus=".close-nav-toggle"></span></div>
                  <div id="mainnav"
                       className="ct-header__mainnav header-navigation-wrapper col-0 col-sm-0 col-md-0 col-lg-8 order-3 order-lg-2">
                    <nav className="primary-menu-wrapper expanded-menu" role="navigation"
                         aria-label="Primary">
                      <ul className="primary-menu menu reset-list-style">
                        <li id="menu-item-32"
                            className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-25 current_page_item menu-item-33 ct-header__link mainlink">
                          <a href="https://www.diw.de/de/diw_01.c.670982.de/seiten/forschungsdatenzentrum_betriebs_und_organisationsdaten_fdz_bo.html" aria-current="page">FDZ-BO</a></li>
                      </ul>
                    </nav>
                  </div>

                </div>
              </div>
            </div>
          </header>
          <div className="infoText">
          <Tabs>
            <TabList>
              <Tab>
                DAS STUDIENPORTAL
              </Tab>
              <Tab>
                ÜBER DAS FDZ-BO
              </Tab>
              <Tab>
                KONTAKT
              </Tab>
            </TabList>
            <TabPanel>
              <h3>Das Studienportal</h3>
              <div className="additional_info_text">
                Das Studienportal liefert einen Überblick über aktuelle und abgeschlossene Studien
                im Bereich der Betriebs- und Organisationsforschung am DIW Berlin. Weiter liefert
                das Studienportal einen Überblick zu Studien im Bereich der Betriebs- und
                Organisationsforschung außerhalb des DIW Berlin, die am FDZ-BO archiviert und
                zugänglich sind.
              </div>
            </TabPanel>
            <TabPanel>
              <h3>Über das FDZ-BO</h3>
              <div className="additional_info_text">
                <p><b>FDZ-BO</b> Das am DIW Berlin ist ein zentrales Archiv für quantitative und qualitative
                Betriebs- und Organisationsdaten. Es archiviert diese, informiert über deren Bestand
                und stellt Datensätze für sekundäranalytische Zwecke zur Verfügung. Über die
                Archivierung von Studien und Datensätzen wird eine langfristige Sicherung und
                nachhaltige Verfügbarkeit der Daten gewährleistet. In Absprache mit den
                verantwortlichen Wissenschaftlerinnen und Wissenschaftlern wird der Zugang zu
                einzelnen Datensätzen als Scientific-Use-Files, via Datenfernverarbeitung oder im
                Rahmen von Gastaufenthalten ermöglicht.</p>
                <p>
                Das FDZ-BO wurde 2010 an der
                Universität Bielefeld gegründet und war bis zu dessen Umsiedlung an das DIW Berlin
                eine Einrichtung der Universität Bielefeld.</p>
              <p><a href={"https://www.diw.de/de/diw_01.c.670982.de/seiten/forschungsdatenzentrum_betriebs-_und_organisationsdaten__fdz-bo.html"}>Forschungsdatenzentrum Betriebs- und Organisationsdaten (FDZ-BO)</a></p></div>
            </TabPanel>
            <TabPanel>
              <h3>Kontakt</h3>
              <div className="additional_info_text">
                <p>Forschungsdatenzentrum Betriebs- und Organisationsdaten (FDZ-BO am Deutschen
                  Institut für Wirtschaftsforschung (DIW Berlin) Mohrenstraße 58 10117 Berlin</p>
                <p>030-897-89-488 (Tobias Gebel, wiss. Mitarbeiter Forschungsdatenmanagement DIW
                  Berlin)</p>
                <p>Email: <a href="mailto:fdz-bo@diw.de"
                             className="text_link text_btn">fdz-bo@diw.de</a></p>
              </div>
            </TabPanel>
          </Tabs>
          </div>
        </Fragment>
    )
  }
}

export default VitrineHeader
