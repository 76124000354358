import React, {useContext} from 'react';
import {
  useWatchList,
  useWatchListDispatch
} from './WatchListContext';


export default function AddWatchListItem({doi,result,prefix}){
  const dispatch = useWatchListDispatch();
  const watchListItems = useWatchList();
  let starSymbol;
  const isActive = watchListItems === null ? false : watchListItems.some(item=> item.doi === doi);

  if(isActive){
    starSymbol=(
        <div className="star-row row">
          <i className="favorite fa-solid fa-star"
             onClick={() => {
               dispatch({
                 type: 'deleted',
                 doi: doi,
                 result:result,
                 prefix:prefix,
               });
             }}></i>
        </div>);
  }else {
    starSymbol=(
        <div className="star-row row">
          <i className="favorite fa-regular fa-star"
             onClick={() => {
               dispatch({
                 type: 'added',
                 doi: doi,
                 result:result,
                 prefix:prefix,
               });
             }}></i>
        </div>);
  }
  return (starSymbol ) ;
}