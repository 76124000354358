import React, { useContext } from 'react'
import LanguageContext from './LanguageContext'
import Accordion from 'react-bootstrap/Accordion'
import { withSearch } from '@elastic/react-search-ui'
import FieldLabels from './FieldLabels'

function PublicationView({ result, addFilter }) {
  const lang = useContext(LanguageContext)
  if (result.publications === undefined || result.publications.raw.length === 0) {
    return null
  }
  return (
    <tr className='publicationDate'>
      <th className='small text-muted pr-2' scope='row key'>
        {FieldLabels.publications[lang]}
      </th>
      <td className='value'>
        <a href='#' onClick={() => addFilter(`publications.startDate.raw`, result?.publications?.raw[0].startDate, 'any')}>
          {result?.publications?.raw[0].startDate}
        </a>
      </td>
    </tr>
  )
}

function DoiView({ result }) {
  if (result.handles === undefined || result.handles.raw.length === 0) {
    return null
  }
  return (
    <tr className='doi'>
      <th className='small text-muted pr-2 key' scope='row'>
        DOI
      </th>
      <td className='list'>
        {result?.handles?.raw.map((obj, index) => {
          if (obj?.type === 'DOI') {
            return (
              <span className='value' key={'creat-' + index}>
                <a target='_blank'
                href={result?.uri?.raw}
                >
                  {obj?.notation}
                </a>
              </span>
            )
          } else {
            return null
          }
        })}
      </td>
    </tr>
  )
}

function CalculatedCoverageView({ result }) {
  const lang = useContext(LanguageContext)
  if (result.calculatedTemporalCoverage === undefined || result.calculatedTemporalCoverage?.raw.length === 0) {
    return null
  }
  return (
    <tr className='calculatedTemporalCoverage'>
      <th className='small text-muted pr-2 key' scope='row'>
        {FieldLabels.temporalCoverages[lang]}
      </th>
      <td className='list'>
        <span className='value'>{result.calculatedTemporalCoverage.raw.label}</span>
      </td>
    </tr>
  )
}

function DocumentIdView({ result }) {
  if (result.documentId === undefined || result.documentId.raw.length === 0) {
    return null
  }
  return (
    <tr className='documentId'>
      <th className='small text-muted pr-2 key' scope='row'>
        Id
      </th>
      <td className='value'>{result?.documentId.raw}</td>
    </tr>
  )
}

function CollectionView({ result, addFilter }) {
  const lang = useContext(LanguageContext)
  if (result.collections === undefined || result.collections?.raw.length === 0) {
    return null
  }
  return (
    <tr className='collection'>
      <th className='small text-muted pr-2 key' scope='row'>
        {FieldLabels.collections[lang]}
      </th>
      <td className='list'>
        {result?.collections?.raw.map((obj, index) => {
          if (obj === undefined) {
            return (
              <span className='value' key={'col-' + index}>
                Loading Data...
              </span>
            )
          } else {
            return (
              <span className='value' key={'col-' + index}>
                <a href='#' onClick={() => addFilter(`collections`, obj, 'any')}>
                  {obj}
                </a>
              </span>
            )
          }
        })}
      </td>
    </tr>
  )
}

function ContributorView({ result, addFilter }) {
  const lang = useContext(LanguageContext)
  if (result.contributions?.raw.length === 0) {
    return null
  }
  return (
    <tr className='contributor'>
      <th className='small text-muted pr-2 key' scope='row'>
        {FieldLabels.contributions[lang]}
      </th>
      <td className='list'>
        {result?.contributions?.raw.map((obj, index) => {
          if (obj?.agent === undefined || obj.agent.label === undefined) {
            return ''
          } else {
            return (
              <span className='value' key={'creat-' + index}>
                <a href='#' onClick={() => addFilter(`contributions.agent.label.raw`, obj.agent.label, 'any')}>
                  {obj.agent.label}
                </a>
              </span>
            )
          }
        })}
      </td>
    </tr>
  )
}

function AbstractView({ result }) {
  const lang = useContext(LanguageContext)
  if (result?.abstract?.raw.length === 0) {
    return null
  }
  return (
    <tr className='abstract'>
      <th className='small text-muted pr-2 key' scope='row'>
        Abstract
      </th>
      <td className='value'>
        <SmartText
          key='1'
          length={120}
          text={
            result.abstract?.raw[lang] === undefined
              ? result.abstract?.raw['pref'] === undefined
                ? ''
                : result.abstract?.raw['pref']
              : result.abstract?.raw[lang]
          }
        />
      </td>
    </tr>
  )
}

function ProjectNotesView({ result }) {
  const lang = useContext(LanguageContext)
  if (result?.projectNotes?.raw.length === 0) {
    return null;
  }
  if(result.projectNotes?.raw[lang] === undefined && result.projectNotes?.raw['pref'] === undefined){
    return null;
  }
  return (
      <tr className='projectNotes'>
        <th className='small text-muted pr-2 key' scope='row'>
          {FieldLabels.notes[lang]}
        </th>
        <td className='value'>
          {
                result.projectNotes?.raw[lang] === undefined
                    ? result.projectNotes?.raw['pref'] === undefined
                        ? ''
                        : result.projectNotes?.raw['pref']
                    : result.projectNotes?.raw[lang]
              }

        </td>
      </tr>
  )
}
const SmartText = ({ text, length = 120 }) => {
  const [showLess, setShowLess] = React.useState(true)

  if (text.length < length) {
    return <p>{text}</p>
  }
  /* eslint-disable */
  return (
    <div>
      <p
        dangerouslySetInnerHTML={{
          __html: showLess ? `${text.slice(0, length)}...` : text
        }}
      ></p>
      <a style={{ color: '#375b9a', cursor: 'pointer' }} onClick={() => setShowLess(!showLess)}>
        &nbsp;View {showLess ? 'More' : 'Less'}
      </a>
    </div>
  )
  /* eslint-enable */
}

function SubjectView({ result, addFilter }) {
  const lang = useContext(LanguageContext)
  if (result.subjects?.raw.length === 0 || result.subjects ===undefined ) {
    return null
  }

  return (
    <tr className='subject'>
      <th className='small text-muted pr-2 key' scope='row'>
        {FieldLabels.subjects[lang]}
      </th>
      <td className=''>
        {result?.subjects?.raw.map((obj, index) => {
          if (obj === undefined) {
            return (
              <span className='value badge rounded-pill' key={'subj-' + index}>
                Loading Data...
              </span>
            )
          } else {
            return (
              <a
                className='value badge rounded-pill'
                href='#'
                onClick={() => addFilter(`subjects.label.${lang}.raw`, obj.label[lang], 'any')}
                key={'subj-' + index}
              >
                {' '}
                {obj.label[lang]}
                {obj.notation}
              </a>
            )
          }
        })}
      </td>
    </tr>
  )
}

export function DprexGroupsView({ result, addFilter }) {
  const lang = useContext(LanguageContext)
  if (result.dprexGroups === undefined || result.dprexGroups?.raw.length === 0) {
    return null
  }
  return (
    <tr className='dprexGroups'>
      <th className='small text-muted pr-2 key' scope='row'>
        {FieldLabels.dprexGroups[lang]}
      </th>
      <td className=''>
        {result?.dprexGroups?.raw.map((obj, index) => {
          if (obj === undefined) {
            return (
              <span className='value badge rounded-pill' key={'subj-' + index}>
                Loading Data...
              </span>
            )
          } else {
            return (
              <a
                className='value badge rounded-pill'
                href='#'
                key={'subj-' + index}
                onClick={() => addFilter(`dprexGroups.label.${lang}.raw`, obj.label[lang], 'any')}
              >
                {obj.label[lang]}
                {obj.notation}
              </a>
            )
          }
        })}
      </td>
    </tr>
  )
}

export function DprexConceptsView({ result, addFilter }) {
  const lang = useContext(LanguageContext)
  if (result.dprexConcepts === undefined || result.dprexConcepts?.raw.length === 0) {
    return null
  }
  return (
    <tr className='dprexConcepts'>
      <th className='small text-muted pr-2 key' scope='row'>
        {FieldLabels.dprexConcepts[lang]}
      </th>
      <td className=''>
        {result?.dprexConcepts?.raw.map((obj, index) => {
          if (obj === undefined) {
            return (
              <span className='value badge rounded-pill' key={'subj-' + index}>
                Loading Data...
              </span>
            )
          } else {
            return (
              <a
                className='value badge rounded-pill'
                href='#'
                key={'subj-' + index}
                onClick={() => addFilter(`dprexConcepts.label.${lang}.raw`, obj.label[lang], 'any')}
              >
                {obj.label[lang]}
                {obj.notation}
              </a>
            )
          }
        })}
      </td>
    </tr>
  )
}

export function DprexTypesView({ result, addFilter }) {
  const lang = useContext(LanguageContext)
  if (result.dprexTypes === undefined || result.dprexTypes?.raw.length === 0) {
    return null
  }
  return (
    <tr className='dprexTypes'>
      <th className='small text-muted pr-2 key' scope='row'>
        {FieldLabels.dprexTypes[lang]}
      </th>
      <td className=''>
        {result?.dprexTypes?.raw.map((obj, index) => {
          if (obj === undefined) {
            return (
              <span className='value badge rounded-pill' key={'subj-' + index}>
                Loading Data...
              </span>
            )
          } else {
            return (
              <a
                className='value badge rounded-pill'
                href='#'
                key={'subj-' + index}
                onClick={() => addFilter(`dprexTypes.label.${lang}.raw`, obj.label[lang], 'any')}
              >
                {obj.label[lang]}
                {obj.notation}
              </a>
            )
          }
        })}
      </td>
    </tr>
  )
}
function TemporalCoverageView({ result }) {
  const lang = useContext(LanguageContext)
  if (result.temporalCoverages === undefined || result.temporalCoverages.raw.length === 0) {
    return null
  }
  return (
    <tr className='temporalCoverage'>
      <th className='small text-muted pr-2 key' scope='row'>
        {FieldLabels.temporalCoverages[lang]}
      </th>
      <td className=''>
        <table className='subTable'>
          <thead>
            <tr>
              <th className='small text-muted pr-2 key' scope='row'>
                Label
              </th>
              <th className='small text-muted pr-2 key' scope='row'>
                Start
              </th>
              <th className='small text-muted pr-2 key' scope='row'>
                End
              </th>
            </tr>
          </thead>
          <tbody>
            {result?.temporalCoverages?.raw.map((obj, index) => {
              if (obj === undefined) {
                return (
                  <span className='value badge rounded-pill' key={'temporalCoverage-' + index}>
                    Loading Data...
                  </span>
                )
              } else {
                return (
                  <tr key={'temporalCoverage-' + index}>
                    <td>
                      {obj.labels.map((labelObject, index) => {
                        return labelObject[lang]
                      })}
                    </td>
                    <td>{obj.startDate}</td>
                    <td>{obj.endDate}</td>
                  </tr>
                )
              }
            })}
          </tbody>
        </table>
      </td>
    </tr>
  )
}

function CollectionModeView({ result }) {
  const lang = useContext(LanguageContext)
  if (result.collectionModes === undefined || result.collectionModes?.raw.length === 0) {
    return null
  }
  return (
    <tr className='collectionMode'>
      <th className='small text-muted pr-2 key' scope='row'>
        {FieldLabels.collectionModes[lang]}
      </th>
        <td className='list'>
          {result?.collectionModes?.raw.map((obj, index) => {
            if (obj?.label === undefined ) {
              if (obj?.type !== undefined ) {
                return <span className="value" key={'collMode-' + index}>
                  {obj.type}
              </span>
              }else {
                return ''
              }
            } else {

              return (
                  <span className='value' key={'collMode-' + index}>
                  {obj.label[lang]}
              </span>
              )
            }
          })}
        </td>
    </tr>
  )
}

function TimeDimensionsView({ result }) {
  const lang = useContext(LanguageContext)
  if (result.timeDimensions === undefined || result.timeDimensions?.raw.length === 0) {
    return null
  }
  return (
    <tr className='timeDimensions'>
      <th className='small text-muted pr-2 key' scope='row'>
        {FieldLabels.timeDimensions[lang]}
      </th>
      <td className=''>
        {result?.timeDimensions?.raw.map((obj, index) => {
          if (obj.timeDimensionType === undefined) {

          } else {
            return obj.timeDimensionType;
          }
          if(obj.timeDimensionsFree === undefined ){
            return '';
          }else{
            return obj.timeDimensionsFree[lang];
          }
        })}
      </td>
    </tr>
  )
}

function SamplingView({ result }) {
  const lang = useContext(LanguageContext)
  if (result.sampling === undefined || result.sampling?.raw.length === 0 || result?.sampling?.raw.method === undefined) {
    return null
  }
  return (
    <tr className='timeDimensions'>
      <th className='small text-muted pr-2 key' scope='row'>
        {FieldLabels.sampling[lang]}
      </th>
      <td className=''>{result?.sampling?.raw.method[lang]}</td>
    </tr>
  )
}

function UniverseView({ result }) {
  const lang = useContext(LanguageContext)
  if (result.universe === undefined || result.universe?.raw.length === 0 || result?.universe?.raw.sampled === undefined) {
    return null
  }
  return (
    <tr className='universe'>
      <th className='small text-muted pr-2 key' scope='row'>
        {FieldLabels.universe[lang]}
      </th>
      <td className=''>{result?.universe?.raw.sampled[lang]}</td>
    </tr>
  )
}

function RightsView({ result }) {
  const lang = useContext(LanguageContext)
  if (result.rights === undefined || result.rights?.raw.length === 0 || result?.rights?.raw.label === undefined) {
    return null
  }
  return (
      <tr className='rights'>
        <th className='small text-muted pr-2 key' scope='row'>
          {FieldLabels.rights[lang]}
        </th>
        <td className=''>{result?.rights.raw.label[lang]}</td>
      </tr>
  )
}

function GeographicCoveragesView({ result, addFilter }) {
  const lang = useContext(LanguageContext)
  if (result.geographicCoverages === undefined || result.geographicCoverages?.raw.length === 0) {
    return null
  }
  return (
    <tr className='geographicCoverages'>
      <th className='small text-muted pr-2 key' scope='row'>
        {FieldLabels.geographicCoverages[lang]}
      </th>
      <td className=''>
        <ul className='inline-list'>
          {result?.geographicCoverages?.raw.map((obj, index) => {
            if (obj.geographicCoverageControlled === undefined) {
              if(obj.geographicCoveragesFree === undefined){
                return '';
              } else{
                return (
                    <li key={'geographicCoverages-' + index}>
                        {obj.geographicCoveragesFree[lang]}
                    </li>
                )
              }

            } else {
              //         let twoCharCountryCode = obj.geographicCoverageControlled.length > 2 ? obj.geographicCoverageControlled.substring(0, 2) : obj.geographicCoverageControlled;
              return (
                <li key={'geographicCoverages-' + index}>
                  <a
                    href='#'
                    onClick={() =>
                      addFilter(`geographicCoverages.geographicCoverageControlled.raw`, obj.geographicCoverageControlled, 'any')
                    }
                  >
                    {obj.geographicCoverageControlled}
                  </a>
                </li>
              )
            }
          })}
        </ul>
      </td>
    </tr>
  )
}

function HitMetadataView({ result, addFilter }) {
  const lang = useContext(LanguageContext)
  return (
    <div className='sui-result__body body container'>
      <div>
        <table className='result row table badge-pill'>
          <tbody>
            <PublicationView result={result} />
            <CalculatedCoverageView result={result} />
            <DocumentIdView result={result} />
            <CollectionView result={result} />
            <ContributorView result={result} addFilter={addFilter} />
            <AbstractView result={result} />
            <SubjectView result={result} addFilter={addFilter} />
            <DprexConceptsView result={result} addFilter={addFilter} />
            <DprexGroupsView result={result} addFilter={addFilter} />
            <DprexTypesView result={result} addFilter={addFilter} />
            <DoiView result={result} />
          </tbody>
        </table>

        <Accordion className={"metadataAccordion"}>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>{FieldLabels.hitSubHeader[lang]}</Accordion.Header>
            <Accordion.Body>
              <table className='result row table badge-pill'>
                <tbody>
                  <CollectionModeView result={result} />
                  <SamplingView result={result} />
                  <TemporalCoverageView result={result} />
                  <TimeDimensionsView result={result} />
                  <UniverseView result={result} />
                  <GeographicCoveragesView result={result} addFilter={addFilter} />
                  <ProjectNotesView result={result} addFilter={addFilter} />
                  <RightsView  result={result}  />
                </tbody>
              </table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  )
}

export default withSearch(({ addFilter }) => ({
  addFilter
}))(HitMetadataView)
