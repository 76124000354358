import { FilterType, SearchQuery } from '@elastic/search-ui'
import { default as ResultFields } from '../../ResultFields'
const filterType: FilterType = 'none'
const VitrineQuery: SearchQuery = {
  filters: [{ type: filterType, field: 'issuer', values: ['/*Insert String here and set type to "all"*/'] }],
  result_fields: ResultFields,
  disjunctiveFacets: [
    'publication.startDate.raw',
    'calculatedTemporalCoverage.dates',
    'geographicCoverages.geographicCoverageControlled.raw'
  ],
  facets: {
    'subjects.label.de.raw': { type: 'value', size: 512 },
    'subjects.label.en.raw': { type: 'value', size: 512 },
    'dprexConcepts.label.de.raw': { type: 'value', size: 512 },
    'dprexConcepts.label.en.raw': { type: 'value', size: 512 },
    'dprexGroups.label.de.raw': { type: 'value', size: 512 },
    'dprexGroups.label.en.raw': { type: 'value', size: 512 },
    'dprexTypes.label.de.raw': { type: 'value', size: 512 },
    'dprexTypes.label.en.raw': { type: 'value', size: 512 },
    'subjects.uri': { type: 'value', size: 512 },
    'subjects.label.pref.raw': { type: 'value', size: 512 },
    'publications.startDate.raw': { type: 'value', size: 512 },
    'contributions.agent.label.raw': { type: 'value', size: 2024 },
    'geographicCoverages.geographicCoverageControlled.raw': { type: 'value', size: 2024 },
    'collections.raw': { type: 'value', size: 512 },
    'issuers.label.pref.raw': { type: 'value', size: 2024 },
    'issuers.label.de.raw': { type: 'value', size: 2024 },
    'issuers.label.en.raw': { type: 'value', size: 2024 },
    'searchappPublisher.name.en.raw': { type: 'value', size: 2024 },
    'searchappPublisher.name.de.raw': { type: 'value', size: 2024 },
    'calculatedTemporalCoverage.dates': {
      type: 'range',
      ranges: [
        { from: 2020, name: '2020 - ' },
        { from: 2015, to: 2020, name: '2015 - 2020' },
        { from: 2010, to: 2015, name: '2010 - 2015' },
        { from: 2005, to: 2010, name: '2005 - 2010' },
        { from: 2000, to: 2005, name: '2000 - 2005' },
        { from: 1995, to: 2000, name: '1995 - 2000' },
        { from: 1990, to: 1995, name: '1990 - 1995' },
        { from: 1985, to: 1990, name: '1985 - 1990' },
        { from: 1980, to: 1985, name: '1980 - 1985' }
      ]
    }
  }
}
export default VitrineQuery
