import { withSearch } from '@elastic/react-search-ui'
import FieldLabels from './FieldLabels'
import { useContext } from 'react'
import LanguageContext from './LanguageContext'

function FilterBox({ filters, clearFilters, removeFilter }) {
  const lang = useContext(LanguageContext)
  if (filters.length == 0) {
    return (
      <div className='filterBox'>

        Filter
        <table className='filterTable table '></table>
      </div>
    )
  } else {
    return (
      <div className='filterBox'>

        Filter
        <table className='filterTable table '>
          <tbody>
            <tr className='deleteAllFilter'>
              <td>
                <b>
                  {' '}
                  <a href='#' onClick={() => clearFilters()}>
                    {FieldLabels.deleteAllFiltersButton[lang]}
                  </a>
                </b>
              </td>
              <td>
                <button className='btn btn-light btn-sm' type='button' onClick={() => clearFilters()}>
                  x
                </button>
              </td>
            </tr>
            {filters.map((f, index) =>
              f.values.map((v) => (
                <tr key={f.field + '-' + v + index}>
                  <td key={v}>
                    <a className='filter' href='#' onClick={() => removeFilter(f.field, v)}>
                      {v}
                    </a>
                  </td>
                  <td>
                    <button className='btn btn-light btn-sm' onClick={() => removeFilter(f.field, v)}>
                      X
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    )
  }
}

export default withSearch(({ filters, clearFilters, removeFilter }) => ({
  filters,
  clearFilters,
  removeFilter
}))(FilterBox)
