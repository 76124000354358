import { createContext, useContext, useReducer } from 'react';
import {useVitrineConfig} from "./VitrineConfigContext";

const WatchListContext = createContext(null);
const WatchListDispatchContext = createContext(null);


export function WatchListProvider ({children,prefix}){

const initialState = () =>{
  let watchListItemsFromCookie = window.localStorage.getItem(prefix+"_watchListItems") ;

  if(watchListItemsFromCookie === null){
    watchListItemsFromCookie=null;
  }
  return JSON.parse(watchListItemsFromCookie);
}
  const [watchListItems,dispatch] = useReducer (watchListReducer,initialState());

  return (
      <WatchListContext.Provider value={watchListItems}>
        <WatchListDispatchContext.Provider value={dispatch}>
          {children}
        </WatchListDispatchContext.Provider>
      </WatchListContext.Provider>
  );
}

export function useWatchList(){
  return useContext(WatchListContext);
}

export function useWatchListDispatch(){
  return useContext(WatchListDispatchContext);
}

function watchListReducer(watchListItems,item){
  let newWatchListItems=[];
  watchListItems=watchListItems===null?[]:watchListItems;
  switch(item.type){
    case 'added': {
      newWatchListItems= [...watchListItems, {
        doi: item.doi,
        result: item.result,
        prefix: item.prefix,
      }];
      break;
    }
    case 'deleted': {
        newWatchListItems= watchListItems.filter(i => i.doi!== item.doi);
        break;
      }
    default: {
        throw Error('Unknown action: ' + item.type);
      }
    }
    window.localStorage.setItem(item.prefix+"_watchListItems",  JSON.stringify(newWatchListItems));
    return newWatchListItems
}
