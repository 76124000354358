import React, {useContext, useState} from "react";
import {useWatchList} from "./WatchListContext";
import SearchView from "./SearchView";
import LanguageSelector from "./LanguageSelector";
import WatchList from "./WatchList";
import styled from "styled-components";
import Modal from "react-overlays/Modal";
import {useVitrineConfig} from "./VitrineConfigContext";
import LanguageContext from "./LanguageContext";
import FieldLabels from "./FieldLabels";


const StyledModal = styled(Modal)`
  position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
  width: ${() => (window.width)-(window.width/5)};
  height: ${() => (window.height)-(window.height/5)};
  top: ${() => (window.height/2)-((window.height)-(window.height/5))};
  left: ${() => (window.width/2)-((window.width)-(window.width/5))};
  overflow-y: scroll;
`;

export function VitrineScreens({language,setLanguage}){
  const lang = useContext(LanguageContext);
  const config = useVitrineConfig()
  const [activeScreen,setActiveScreen] = useState('Search');
  const watchListItems=useWatchList();
  const itemCount=watchListItems ? watchListItems.length : 0 ;
  let screen;

  if(activeScreen === 'Search') {
    screen=(<SearchView
            config={config}
            languageSelector={<LanguageSelector language={language} setLanguage={setLanguage}/>}
            navigation={<a  href="#" onClick={(e)=>{setActiveScreen('Watchlist')}}>{FieldLabels.watchlist[lang]} <i className="favorite fa-solid fa-star"/> ({itemCount})</a>} />
    );
  }else{

    screen= (
          <WatchList
            config={config}
            navigation={<a href="#" onClick={(e)=>{setActiveScreen('Search')}}>{FieldLabels.backToSearch[lang]}</a>}/>
    );
  }
  return (<>


    {screen}

  </>);
}