
import Modal from 'react-overlays/Modal'
import styled from "styled-components";
import React, {useContext, useState} from 'react';
import LanguageContext from "./LanguageContext";
import {Facet} from "@elastic/react-search-ui";
import FieldLabels from "./FieldLabels";

const StyledModal = styled(Modal)`
  position: fixed;
  width: 400px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
`;

const ScrollableFacets= styled("div") `
    overflow-y: scroll;
    max-height: 90vh;
    padding:20px;
`;
function FacetOverlayView({facetName}) {
    const lang = useContext(LanguageContext);
    let all=10000;
    return (
        <ScrollableFacets >
          <Facet
              filterType='any'
              className={facetName}
              field={facetName+'.label.' + lang + '.raw'}
              fieldLabel={facetName+'.label.' + lang + '.raw'}
              label={FieldLabels[facetName][lang]}
              searchPlaceholder={FieldLabels.filterSearchBox[lang] + ' ' + FieldLabels[facetName][lang]}
              show={all}
              isFilterable={true}
          />
          <br/>
        </ScrollableFacets>
    )
}

function FacetOverlay({facetName}) {
    const [show, setShow] = useState(false);

  const lang = useContext(LanguageContext);
    return (
        <div className={facetName}>
            <button className="sui-facet-view-more" type="button"
                    onClick={() => setShow(true)}
            >+ More
            </button>
            <StyledModal
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="modal-label"
            >
                <div>
                    <button
                        type="button"
                        className="btn close-btn"
                        onClick={() => setShow(false)}
                    >x
                    </button>
                    <FacetOverlayView facetName={facetName}/>
                    <button
                        type="button"
                        className="btn btn-primary mb-4 "
                        onClick={() => setShow(false)}
                    >{FieldLabels.apply[lang]}
                    </button>
                </div>
            </StyledModal>

            <p></p>
        </div>
    );
}

export default FacetOverlay