import React from 'react'
import { useContext } from 'react'
import LanguageContext from './LanguageContext'
import HitHeaderView from './HitHeaderView'
import HitMetadataView from './HitMetadataView'
import AddWatchListItem from "./AddWatchListItem";
import {useVitrineConfig} from "./VitrineConfigContext";

function FooterView({ result }) {
  //  Modified: 2023-05-11T11:26:12.043+02:00 Source: https://dbkapps.gesis.or…dbk.gesis.org:DBK/ZA5572
  return (
    <div className='sui-result__body body container hitfooter'>
      <div>
        <table>
          <tbody>
            <tr className='searchappPublisher'>
              <PublisherView result={result} />
              <DescribedByView result={result} />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

function PublisherView({ result }) {
  const lang = useContext(LanguageContext)
  if (result.searchappPublisher === undefined || result.searchappPublisher.raw.length === 0) {
    return null
  }
  return (
    <>
      <td className='small text-muted pr-2 key' scope='row'>
        <a href={result?.searchappPublisher?.raw.url}>
          {(() => {
            if (result?.searchappPublisher?.raw.shortName === 'GESIS') {
              return <img className='publisherIcon' src='logos/gesis.png' />
            } else if (result?.searchappPublisher?.raw.shortName === 'Qualiservice') {
              return <img className='publisherIcon' src='logos/qualiservice.png' />
            }else if (result?.searchappPublisher?.raw.shortName === 'DPREX') {
              return <img className='publisherIcon' src='logos/dprex.png' />
            }else if (result?.searchappPublisher?.raw.shortName === 'DEZIM') {
              return <img className='publisherIcon' src='logos/dezim.png' />
            }
          })()}
        </a>
      </td>
      <td className='small text-muted pr-2 key'>
        {(() => {
          if (result?.searchappPublisher?.raw?.name != undefined) {
            return result?.searchappPublisher?.raw?.name[lang]
          }
        })()}
      </td>
      <td></td>
    </>
  )
}

function DescribedByView({ result }) {
  return (
    <td>
      <div className='footer-list'>
        <ol className='list'>
          <li className='value' key='documentUri'>
            <b>Index:</b>{' '}
            <a target='_blank' href={result?.describedBy?.raw.uri} rel='noreferrer'>
              {result?.documentId?.raw} &#128279;
            </a>
          </li>
          <li className='value' key='lastModified'>
            <b>Updated:</b> {result?.describedBy?.raw.modifiedBy?.dateModified}
          </li>
          {(() => {
            if (
              result?.describedBy?.raw.modifiedBy?.resultOf?.srcObject?.inDataset?.label === 'DBK_OAI' ||
              result?.describedBy?.raw.modifiedBy?.resultOf?.srcObject?.inDataset?.label === 'PANGAEA_OAI' ||
              result?.describedBy?.raw.modifiedBy?.resultOf?.srcObject?.inDataset?.label === 'DEZIM'

            ) {
              return (
                <li className='value' key='source'>
                  <b>From:</b>{' '}
                  <a target='_blank' href={result?.describedBy?.raw.modifiedBy?.resultOf?.srcObject?.uri} rel='noreferrer'>
                    {result?.describedBy?.raw.modifiedBy?.resultOf?.srcObject?.inDataset?.label} &#128279;
                  </a>
                </li>
              )
            }
            return (
              <li className='value' key='source'>
                <b>From:</b> <a target='_blank'> internal_source &#128279;</a>
              </li>
            )
          })()}
        </ol>
      </div>
    </td>
  )
}

function HitView({ result, onClickLink }) {

  const vitrineConfig = useVitrineConfig();
  return (
    <li className='sui-result hit container' key={result.uri.raw}>
      <AddWatchListItem doi={result?.uri?.raw} result={result} prefix={vitrineConfig.vitrinePartner}/>
      <HitHeaderView result={result} onClickLink={onClickLink} />
      <HitMetadataView result={result} />
      <FooterView result={result} />
    </li>
  )
}

export default HitView
