import { Facet } from '@elastic/react-search-ui'
import { default as FieldLabels } from './FieldLabels'
import { useContext } from 'react'
import LanguageContext from './LanguageContext'
import FacetOverlay from "./FacetOverlay";
function FacetView() {
  const lang = useContext(LanguageContext);

  return (
      <div>
          <Facet
              className='subject'
              field={'subjects.label.' + lang + '.raw'}
              fieldLabel={'subjects.label.' + lang + '.raw'}
              label={FieldLabels.subjects[lang]}
              filterType='any'
              isFilterable={true}
              showMore={false}
              searchPlaceholder={FieldLabels.filterSearchBox[lang] + ' ' + FieldLabels.subjects[lang]}
          />
          <Facet
              className='dprexConcepts'
              field={'dprexConcepts.label.' + lang + '.raw'}
              fieldLabel={'dprexConcepts.label.' + lang + '.raw'}
              label={FieldLabels.dprexConcepts[lang]}
              filterType='any'
              isFilterable={true}
              showMore={false}
              searchPlaceholder={FieldLabels.filterSearchBox[lang] + ' ' + FieldLabels.dprexConcepts[lang]}
          /><FacetOverlay facetName='dprexConcepts'/>
          <Facet
              className='dprexGroups'
              field={'dprexGroups.label.' + lang + '.raw'}
              fieldLabel={'dprexGroups.label.' + lang + '.raw'}
              label={FieldLabels.dprexGroups[lang]}
              filterType='any'
              isFilterable={true}
              showMore={false}
              searchPlaceholder={FieldLabels.filterSearchBox[lang] + ' ' + FieldLabels.dprexGroups[lang]}
          /><FacetOverlay facetName='dprexGroups'/>
          <Facet
              className='dprexTypes'
              field={'dprexTypes.label.' + lang + '.raw'}
              fieldLabel={'dprexTypes.label.' + lang + '.raw'}
              label={FieldLabels.dprexTypes[lang]}
              filterType='any'
              isFilterable={true}
              showMore={false}
              searchPlaceholder={FieldLabels.filterSearchBox[lang] + ' ' + FieldLabels.dprexTypes[lang]}
          /><FacetOverlay facetName='dprexTypes'/>
          <Facet
              className='geographicCoverages'
              field='geographicCoverages.geographicCoverageControlled.raw'
              label={FieldLabels.geographicCoverages[lang]}
              filterType='any'
              isFilterable={true}
              showMore={false}
              searchPlaceholder={FieldLabels.filterSearchBox[lang] + ' ' + FieldLabels.geographicCoverages[lang]}
          />
          <Facet
              className='calculatedTemporalCoverage'
              field='calculatedTemporalCoverage.dates'
              label={FieldLabels.calculatedTemporalCoverage[lang]}
              filterType='any'
              isFilterable={true}
              showMore={false}
              searchPlaceholder={FieldLabels.filterSearchBox[lang] + ' ' + FieldLabels.calculatedTemporalCoverage[lang]}
          />
          <Facet
              className='publicationDate'
              field='publications.startDate.raw'
              label={FieldLabels.publications[lang]}
              filterType='any'
              isFilterable={true}
              showMore={false}
              searchPlaceholder={FieldLabels.filterSearchBox[lang] + ' ' + FieldLabels.publications[lang]}
          />
          <Facet
              className='contributors'
              field='contributions.agent.label.raw'
              label={FieldLabels.contributions[lang]}
              filterType='any'
              isFilterable={true}
              showMore={false}
              searchPlaceholder={FieldLabels.filterSearchBox[lang] + ' ' + FieldLabels.contributions[lang]}
          />
          <Facet
              className='issuer'
              field={'issuers.label.' + lang + '.raw'}
              label={FieldLabels.issuers[lang]}
              filterType='any'
              isFilterable={true}
              showMore={false}
              searchPlaceholder={FieldLabels.filterSearchBox[lang] + ' ' + FieldLabels.issuers[lang]}
          />
          <Facet
              className='collection'
              field={'collections.raw'}
              label={FieldLabels.collections[lang]}
              filterType='any'
              isFilterable={true}
              showMore={false}
              searchPlaceholder={FieldLabels.filterSearchBox[lang] + ' ' + FieldLabels.collections[lang]}
          />
          <Facet
              className='searchappPublisher'
              field={'searchappPublisher.name.' + lang + '.raw'}
              label={FieldLabels.searchappPublisher[lang]}
              filterType='any'
              isFilterable={true}
              showMore={false}
              searchPlaceholder={FieldLabels.filterSearchBox[lang] + ' ' + FieldLabels.searchappPublisher[lang]}
          />
          <br/>
      </div>
  )
}

export default FacetView
