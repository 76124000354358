import 'bootstrap/dist/css/bootstrap.min.css'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import './base_css/search.css'
import './base_css/customStyle.css'
import { WatchListProvider} from "./views/WatchListContext";
import LanguageContext from './views/LanguageContext'
import { useState} from "react";
import {SearchProvider} from "@elastic/react-search-ui";
import Favicon from "react-favicon";
import {Helmet, HelmetData} from "react-helmet-async";
import {VitrineScreens} from "./views/VitrineScreens";
import {useVitrineConfig, VitrineConfigProvider} from "./views/VitrineConfigContext";




const helmetData = new HelmetData({})

function Vitrine() {
  const vitrineConfig = useVitrineConfig() ;
  const [language, setLanguage] = useState( 'de')
  return (<>
      <LanguageContext.Provider value={language}>
        <WatchListProvider prefix={vitrineConfig.vitrinePartner}>
          <SearchProvider config={vitrineConfig.searchConfig}>
            <Favicon url={'./favicons/' + vitrineConfig.vitrinePartner + '.ico'} />
              <Helmet helmetData={helmetData}>
                <title>{vitrineConfig.vitrinePageTitle} Suche</title>
                <link rel='stylesheet' type='text/css' href={process.env.PUBLIC_URL + '/css/' + vitrineConfig.vitrineThemeFileName} />
                <link rel='stylesheet' type='text/css' href={vitrineConfig.vitrineRemoteThemeFile} />
              </Helmet>
              <VitrineScreens language={language} setLanguage={setLanguage}/>
          </SearchProvider>
        </WatchListProvider>
      </LanguageContext.Provider></>);
}

export default function App(config) {
 return (
     <VitrineConfigProvider config={config}>
         <Vitrine  />
     </VitrineConfigProvider>
 );
}
