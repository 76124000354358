const FieldLabels = {
  notSpec: {
    de: 'Keine Angabe',
    en: 'Not specified'
  },
  hitSubHeader: {
    de: 'Methodologie',
    en: 'Methodology'
  },
  subjects: {
    de: 'Themen',
    en: 'Topics'
  },
  publications: {
    de: 'Publikationsjahr',
    en: 'Publication Year'
  },
  contributions: {
    de: 'Beteiligte',
    en: 'Contributors'
  },
  issuers: {
    de: 'Herausgeber',
    en: 'Publisher'
  },
  collections: {
    de: 'Sammlungshinweis',
    en: 'Collection'
  },
  temporalCoverages: {
    de: 'Erhebungszeitraum',
    en: 'Date(s) of Data Collection'
  },
  collectionModes: {
    de: 'Erhebungsmodus',
    en: 'Mode of Data Collection'
  },
  timeDimensions: {
    de: 'Zeitliches Forschungsdesign',
    en: 'Temporal Research Design'
  },
  sampling: {
    de: 'Auswahlverfahren',
    en: 'Sampling Procedure'
  },
  universe: {
    de: 'Grundgesamtheit',
    en: 'Universe'
  },
  geographicCoverages: {
    de: 'Untersuchungsgebiet',
    en: 'Geographic coverage'
  },
  dprexGroups: {
    de: 'Differenzierungsmerkmale',
    en: 'Diversification criteria'
  },
  dprexConcepts: {
    de: 'Konzepte',
    en: 'Concepts'
  },
  dprexTypes: {
    de: 'Datentypen',
    en: 'Datatypes'
  },
  searchappPublisher: {
    de: 'Datenzentren und -kollektionen',
    en: 'Datacenters and data collections'
  },
  calculatedTemporalCoverage: {
    de: 'Erhebungszeitraum',
    en: 'Date(s) of Data Collection'
  },
  deleteAllFiltersButton: {
    de: 'Alle Filter löschen',
    en: 'Delete all Filters'
  },
  filterSearchBox: {
    de: 'Suche Filter für',
    en: 'Find filters for'
  },
  searchBox: {
    de: 'Suche',
    en: 'Search'
  },
  apply: {
    de: 'Übernehmen',
    en: 'Apply'
  },
  watchlist: {
    de: 'Merkliste',
    en: 'Watchlist'
  },
  printWatchlist: {
    de: 'Merkliste Drucken',
    en: 'Print Watchlist'
  },
  backToSearch: {
    de: "Zurück zur Suche",
    en: "Back to Search"
  },
  watchlistIsEmpty: {
    de: "Merkliste ist leer",
    en: "Watchlist is empty"
  },
  emptyWatchlist: {
    de: "Merkliste leeren",
    en: "Clear watchlist"
  },
  notes:{
    de: "Bemerkungen",
    en: "Notes"
  },
  more: {
    de: "Mehr",
    en: "More"
  },
  rights: {
    de: "Lizenz",
    en: "Lizenz"
  }
}

export default FieldLabels
