import { withSearch } from '@elastic/react-search-ui'

function LanguageSelector({ filters, clearFilters, language, setLanguage }) {
  return (
    <div className='language-picker js-language-picker'>
      <select
        value={language}
        name='language-picker-select'
        id='language-picker-select'
        onChange={(e) => {
          clearFilters()
          setLanguage(e.target.value)
        }}
      >
        <option lang='de' value='de' key='de'>
          de
        </option>
        <option lang='en' value='en' key='en'>
          en
        </option>
      </select>
    </div>
  )
}

export default withSearch(({ filters, clearFilters }) => ({
  filters,
  clearFilters
}))(LanguageSelector)
