import React, { Fragment } from 'react'

/* eslint  no-console: 0 */
class VitrineHeader extends React.Component {
  render() {
    return (
      <Fragment>
        <header id="site-header" className="" role="banner">
          <div className="">

            <div className="">
              <div className="row" id="">

                <div id="mainlogo"
                     className="mainlogo ct-header__mainlogo site-logo col-8 col-lg-4 order-1 order-lg-1">
                  <a className="mainlogo-link logo-link" href="https://suche.datenportal-rechtsextremismus.de">
                     <span className="mainlogo-graf">
                        <svg version="1.1" className="icon icon-logo"
                             xmlns="http://www.w3.org/2000/svg"
                             xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                             width="258.321px" height="109px" viewBox="1 0 258.321 109"
                             enableBackground="new 1 0 258.321 109" xmlSpace="preserve">
      <polygon className="st4" fill="#292B33" points="130,109 133,109 133,0 130,0 "/>
      <path className="st3" d="M39.768,60.252c-0.651,1.75-1.624,3.288-2.904,4.604c-1.285,1.324-2.909,2.354-4.872,3.098
         c-1.963,0.744-4.225,1.116-6.794,1.116H13.353V40.293v-0.004h11.845c0.16,0,0.32,0,0.477,0.004c2.374,0.044,4.466,0.429,6.282,1.147
         c1.937,0.767,3.56,1.812,4.868,3.128c1.311,1.321,2.293,2.845,2.944,4.568c0.433,1.144,0.723,2.339,0.866,3.589
         c0.08,0.638,0.116,1.289,0.116,1.954C40.75,56.646,40.424,58.502,39.768,60.252 M51.542,44.856c-0.687-1.626-1.535-3.15-2.547-4.563
         c-0.879-1.237-1.882-2.384-3.012-3.456c-2.422-2.304-5.34-4.103-8.757-5.396c-3.417-1.298-7.303-1.945-11.662-1.945H1v9.805v0.992
         v39.564h24.563c4.31,0,8.2-0.646,11.662-1.94c3.462-1.298,6.397-3.084,8.793-5.361c2.4-2.276,4.238-4.94,5.523-7.988
         c1.285-3.044,1.927-6.339,1.927-9.889c0-0.66-0.021-1.312-0.066-1.954C53.206,49.951,52.585,47.329,51.542,44.856"/>
      <polygon className="st5" points="161.859,59.211 187.73,59.211 187.73,49.282 161.859,49.282 161.859,39.93 191,39.93
         191,29.495 149.867,29.495 149.867,79.857 191.728,79.857 191.728,69.428 161.859,69.428 "/>
      <polygon className="st5" points="238.434,54.865 240.129,52.725 249.975,40.293 258.522,29.496
         243.622,29.496 235.561,40.293 231.27,46.048 226.67,40.293 218.046,29.496 202.565,29.496 211.546,40.293 221.887,52.725
         222.994,54.059 202.204,79.857 217.101,79.857 230.257,62.977 243.841,79.857 259.321,79.857 "/>
      <path className="st5" d="M103.431,66.592c-2.762,1.533-5.889,2.305-9.377,2.305h-2.985l7.428,10.961h14.245
         l-9.172-13.345C103.525,66.539,103.48,66.566,103.431,66.592"/>
      <path className="st3" d="M106.656,54.931c0.441-0.708,0.821-1.444,1.138-2.206c0.865-2.051,1.298-4.303,1.298-6.752
         c0-2.025-0.326-3.917-0.986-5.68c-0.348-0.953-0.799-1.861-1.343-2.739c-1.548-2.494-3.646-4.461-6.286-5.901
         c-2.641-1.435-5.63-2.157-8.972-2.157H64.547v10.797h12.135v-0.005h11.987c0.058,0,0.116,0,0.174,0.005
         c1.338,0.018,2.552,0.257,3.645,0.713c1.138,0.483,2.043,1.152,2.722,2.016c0.678,0.864,1.017,1.919,1.017,3.168
         c0,1.246-0.338,2.361-1.017,3.345c-0.679,0.984-1.583,1.764-2.722,2.335c-1.049,0.527-2.204,0.815-3.471,0.855
         c-0.116,0.009-0.232,0.009-0.348,0.009H76.682v-0.009H64.547v27.132H76.9V63.455h13.803c3.488,0,6.616-0.766,9.377-2.303
         C102.842,59.619,105.032,57.545,106.656,54.931"/>
   </svg>                     </span>
                  </a>
                </div>





                <div id="mainnav"
                     className="ct-header__mainnav header-navigation-wrapper col-0 col-sm-0 col-md-0 col-lg-8 order-3 order-lg-2">
                  <nav className="r" role=""
                       aria-label="Primary">
                    <ul className="active primary-menu ">
                      <li id="menu-item-601"
                          className="active menu-item menu-item-type-post_type menu-item-object-page current-page-ancestor current-menu-ancestor current-menu-parent current-page-parent current_page_parent current_page_ancestor menu-item-has-children menu-item-601 ct-header__link mainlink">
                        <a href="https://datenportal-rechtsextremismus.de/rex-forschungsdaten/">
                          R|EX
                          -Forschungsdaten</a><span className="icon"></span>

                      </li>
                      <li id="menu-item-588"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-588 ct-header__link mainlink">
                        <a href="https://datenportal-rechtsextremismus.de/ueber-dp-rex/">Über DP-R|EX
                        </a><span className="icon"></span>

                      </li>
                      <li id="menu-item-38"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-38 ct-header__link mainlink">
                        <a href="https://dprex.hypotheses.org/">Blog</a></li>
                    </ul>
                    <ul className="sub-menu active">
                      <li id="menu-item-604"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-604 ct-header__link sublink">
                        <a href="https://datenportal-rechtsextremismus.de/rex-forschungsdaten/data-sharing-support/">Daten
                          teilen</a></li>
                      <li id="menu-item-752"
                          className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-743 current_page_item menu-item-752 ct-header__link sublink">
                        <a href="https://datenportal-rechtsextremismus.de/rex-forschungsdaten/suche/"
                           aria-current="page">Daten suchen</a></li>
                      <li id="menu-item-602"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-602 ct-header__link sublink">
                        <a href="https://datenportal-rechtsextremismus.de/rex-forschungsdaten/digitale-rex-daten/">Digitale
                          Daten</a></li>
                    </ul>
                  </nav>
                </div>

              </div>
            </div>

          </div>

        </header>
      </Fragment>
    )
  }
}

export default VitrineHeader
