import HitHeaderView from "./HitHeaderView";
import React from "react";
import {DprexTypesView, DprexGroupsView,DprexConceptsView} from './HitMetadataView';
import AddWatchListItem from "./AddWatchListItem";
import {useVitrineConfig} from "./VitrineConfigContext";

export function HitViewShort({ result, onClickLink }) {
  const vitrineConfig = useVitrineConfig();
  return (
      <>
        <AddWatchListItem doi={result?.uri?.raw} result={result} prefix={vitrineConfig.vitrinePartner} />
        <HitHeaderView result={result} onClickLink={onClickLink} />

        <table className='result row table badge-pill'>
          <tbody>
              <DprexTypesView  result={result} onClickLink={onClickLink} />
              <DprexGroupsView  result={result} onClickLink={onClickLink} />
              <DprexConceptsView  result={result} onClickLink={onClickLink} />
              </tbody>
         </table>
      </>
  )
}

