import React, { Fragment } from 'react'

/* eslint  no-console: 0 */
class VitrineFooter extends React.Component {
  render() {
    return (
        <Fragment>

          <footer>
            <div className="container">
              <div className="top-row text-center">
                <h2>Soziale Medien</h2>
                <p>
                  Verpassen Sie keine Updates mehr. Sie finden das DIW Berlin auf folgenden
                  Kanälen: </p>
                <ul className="social_icons">
                  <li className="tw"><a href="https://twitter.com/DIW_Berlin" target="_blank"
                                        title="Twitter" aria-label="Link zu Twitter"><i
                      className="fab fa-twitter" aria-hidden="true"></i></a></li>
                  <li className="insta"><a href="https://www.instagram.com/diw_berlin"
                                           target="_blank" title="Instagram"
                                           aria-label="Link zu Instagram"><i
                      className="fab fa-instagram" aria-hidden="true"></i></a></li>
                  <li className="fb"><a href="https://www.facebook.com/diw.de" target="_blank"
                                        title="Facebook" aria-label="Link zu Facebook"><i
                      className="fab fa-facebook-f" aria-hidden="true"></i></a>
                  </li>
                  <li className="ytb"><a href="https://www.youtube.com/user/DIWBerlin"
                                         target="_blank" title="Youtube"
                                         aria-label="Link zu Youtube"><i className="fab fa-youtube"
                                                                         aria-hidden="true"></i></a>
                  </li>
                  <li className="lin"><a href="https://www.linkedin.com/company/diw-berlin"
                                         target="_blank" title="LinkedIn"
                                         aria-label="Link zu LinkedIn"><i
                      className="fab fa-linkedin-in" aria-hidden="true"></i></a></li>
                  <li className="rss"><a
                      href="https://www.diw.de/de/diw_01.c.441092.de/ueber_uns/rss_feeds.html"
                      target="_blank" title="RSS feeds" aria-label="Link zu RSS feeds"><i
                      className="fas fa-rss" aria-hidden="true"></i></a></li>
                </ul>
              </div>
            </div>
          </footer>
          <footer className="second_footer">
            <div className="container">
              <div className="row bottom-row">
                <div className="external_link">
                  <ul className="list-inline">
                    <li><a className="text_btn" href="https://www.leibniz-gemeinschaft.de/"
                           target="_blank">Leibniz Gemeinschaft<i
                        className="fas fa-external-link-alt"></i></a></li>
                    <li><a className="text_btn" href="https://www.diw.de/de/diw_01.c.617346.de/karriere.html"
                           target="_blank">Karriere<i className="fas fa-external-link-alt"></i></a>
                    </li>
                    <li><a className="text_btn" href="https://diw-econ.de/" target="_blank">DIW ECON<i
                        className="fas fa-external-link-alt"></i></a></li>
                  </ul>
                </div>
                <ul className="col-lg-6 col-md-5 col-sm-12 text-right impressum">
                  <li><a className="text_btn"
                         href="https://www.diw.de/de/diw_01.c.827375.de/seiten/erklaerung_zur_barrierefreiheit.html">Barrierefreiheit</a>
                  </li>
                  <li><a className="text_btn"
                         href="https://www.diw.de/de/diw_01.c.623806.de/seiten/impressum.html">Impressum</a></li>
                  <li><a className="text_btn"
                         href="https://www.diw.de/de/diw_01.c.605562.de/seiten/datenschutzerklaerung_des_diw_berlin.html">Datenschutz</a>
                  </li>
                </ul>
                <div className="col-12 text-center copyright order-first">
                  <p>© Copyright 2024 Deutsches Institut für Wirtschaftsforschung e.V.</p>
                </div>
              </div>
            </div>
          </footer>
        </Fragment>
    )
  }
}

export default VitrineFooter
