import React, { Fragment } from 'react'

/* eslint  no-console: 0 */
class VitrineHeader extends React.Component {
  render() {
    return (
      <Fragment>
        <div className='header'>
          <h4>Header</h4>
          <p>
            <em>Default</em>
          </p>
        </div>
      </Fragment>
    )
  }
}

export default VitrineHeader
