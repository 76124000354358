import React, { Fragment } from 'react'

/* eslint  no-console: 0 */
class VitrineFooter extends React.Component {
  render() {
    return (
      <Fragment>
        <footer>
          <div>
            <nav id='footer-menu' aria-label='Hilfe &amp; Kontakt'>
              <ul>
                <li>
                  <a href='https://www.uni-konstanz.de/index.php?id=59281' className='touch'>
                    Impressum, Kontakt und Haftungsausschluss
                  </a>
                </li>
                <li>
                  <a href='https://www.uni-konstanz.de/index.php?id=66377' className=''>
                    Datenschutzinformation
                  </a>
                </li>
                <li>
                  <a href='https://www.uni-konstanz.de/index.php?id=108132' className=''>
                    Erklärung zur Barrierefreiheit
                  </a>
                </li>
                <li>
                  <a href='mailto:onlineredaktion@uni-konstanz.de'>Kontakt zur Redaktion</a>
                </li>
              </ul>
              <div className='box'>
                <ul>
                  <li>
                    <a href='app/config/exzclu/exzclu-footer'>Facebook</a>
                  </li>
                  <li>
                    <a href='https://twitter.com/unikonstanz'>Twitter</a>
                  </li>
                  <li>
                    <a href='https://www.youtube.com/user/UniversitaetKonstanz'>YouTube</a>
                  </li>
                  <li>
                    <a href='https://itunes.apple.com/de/institution/universitat-konstanz/id604733564'>iTunes U</a>
                  </li>
                  <li>
                    <a href='app/config/exzclu/exzclu-footer'>Instagram</a>
                  </li>
                </ul>
              </div>
            </nav>
            <i> © Universität Konstanz 2021</i>
          </div>
        </footer>
      </Fragment>
    )
  }
}

export default VitrineFooter
