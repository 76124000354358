import {createContext, useContext, useReducer} from "react";
import ElasticsearchAPIConnector from "@elastic/search-ui-elasticsearch-connector";
import { default as defaultFooter } from '../config/default/footer'
import { default as defaultHeader } from '../config/default/header'
import { default as defaultQuery } from '../config/default/query'
import { default as dprexFooter } from '../config/dprex/footer'
import { default as dprexHeader } from '../config/dprex/header'
import { default as dprexQuery } from '../config/dprex/query'
import { default as exzcluFooter } from '../config/exzclu/footer'
import { default as exzcluHeader } from '../config/exzclu/header'
import { default as exzcluQuery } from '../config/exzclu/query'
import { default as diwFooter } from '../config/diw/footer'
import { default as diwHeader } from '../config/diw/header'
import { default as diwQuery } from '../config/diw/query'

const VitrineConfigContext = createContext({});

function mapConfig(config){
  const query = new URLSearchParams(window.location.search)
  const langParam = query.get('lang')
  const connector = new ElasticsearchAPIConnector({
    host: config.VITRINE_ELASTICSEARCH,
    index: config.VITRINE_INDEXNAME
  })
  const VitrinePartner = config.VITRINE_PARTNER
  let VitrineFooter = defaultFooter
  let VitrineHeader = defaultHeader
  let VitrinePageTitle=""
  let  VitrineDefaultSorting = { field: "publications.startDate", direction: "desc" };
  let VitrineQuery = defaultQuery
  let VitrineRemoteThemeFile = '' //https://datenportal-rechtsextremismus.de/wp-content/themes/dprex/assets/css/gfonts.css?ver=202305";//https://datenportal-rechtsextremismus.de/wp-content/themes/dprex/assets/css/custom.css";
  let VitrineThemeFileName = 'default.css'
  if ('dprex' === VitrinePartner) {
    VitrineFooter = dprexFooter
    VitrineHeader = dprexHeader
    VitrineQuery = dprexQuery
    VitrinePageTitle="DP-R|EX"
    VitrineThemeFileName= 'dprex.css'
  } else if ('exzclu' === VitrinePartner) {
    VitrineFooter = exzcluFooter
    VitrineHeader = exzcluHeader
    VitrineQuery = exzcluQuery
    VitrinePageTitle="exzclu"
    VitrineThemeFileName= 'exzclu.css'
  }else if ('diw' === VitrinePartner) {
    VitrineFooter = diwFooter
    VitrineHeader = diwHeader
    VitrineQuery = diwQuery
    VitrinePageTitle="DIW FDZ-BO"
    VitrineThemeFileName= 'diw.css'
  }else if ('null' === VitrinePartner){
    VitrineDefaultSorting = {};
  }
  let vitrineConfig ={
    vitrinePartner: VitrinePartner,
    vitrineFooter: VitrineFooter,
    vitrineHeader: VitrineHeader,
    vitrineQuery: VitrineQuery,
    vitrinePageTitle: VitrinePageTitle,
    vitrineThemeFileName: VitrineThemeFileName,
    vitrineRemoteThemeFile: VitrineRemoteThemeFile,
    vitrineLang: langParam,
    searchConfig: {
      onSearch: (requestState, queryConfig, next) => {
        let updatedState = requestState;
        if (requestState.searchTerm === "" && requestState.sortList.length === 0) {
          updatedState = {
            ...requestState,
            sortList: [VitrineDefaultSorting]
          };
        }
        return Promise.resolve(next(updatedState, queryConfig));
      },
      alwaysSearchOnInitialLoad: true,
      apiConnector: connector,
      hasA11yNotifications: true,
      searchQuery: VitrineQuery,
      autocompleteQuery: {
        results: {
          resultsPerPage: 5,
          result_fields: {
            title: {
              snippet: {
                size: 100,
                fallback: true
              }
            }
          }
        },
        suggestions: {
          types: {
            documents: {
              fields: ['title.pref.raw']
            }
          },
          size: 4
        }
      }
    }
  }
  return vitrineConfig;
}

export function VitrineConfigProvider ({config,children}){
  let vitrineConfig = mapConfig(config);
  return (
      <VitrineConfigContext.Provider value={vitrineConfig}>
          {children}
      </VitrineConfigContext.Provider>
  );
}

export function useVitrineConfig(){
  return useContext(VitrineConfigContext);
}