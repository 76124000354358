import React, { useContext } from 'react'
import LanguageContext from './LanguageContext'
import { withSearch } from '@elastic/react-search-ui'

function HitHeaderView({ result, onClickLink, addFilter }) {
  const lang = useContext(LanguageContext)
  return (
    <div className='header row'>
      <div className='hittitle row'>

        <h3 title={result?.title?.raw[lang]}>
          {/* Maintain onClickLink to correct track click throughs for analytics*/}
          <a onClick={onClickLink} href={result?.uri?.raw} target='_blank' rel='noreferrer'>
            {result?.title?.raw[lang]}
          </a>
        </h3>
      </div>
      <div className='contributors-title-list row'>
        <ol className='list'>
          {result?.contributions?.raw?.map((obj, index) => {
            if (obj === undefined || obj.agent === undefined) {
              return ''
            } else {
              return (
                <li className='value' key={'creat-' + index}>
                  <a href='#' onClick={() => addFilter(`contributions.agent.label.raw`, obj.agent.label, 'any')}>
                    {obj.agent.label}
                  </a>
                </li>
              )
            }
          })}
        </ol>
      </div>
    </div>
  )
}

export default withSearch(({ addFilter }) => ({
  addFilter
}))(HitHeaderView)
