import {
  ErrorBoundary, Paging,
  PagingInfo,
  Results, ResultsPerPage,
  SearchBox, withSearch, WithSearch
} from "@elastic/react-search-ui";
import {Layout} from "@elastic/react-search-ui-views";
import FieldLabels from "./FieldLabels";
import FilterBox from "./FilterBox";
import FacetView from "./FacetView";
import HitView from "./HitView";

const CustomPagingInfoView = ({ start, end }) => (
    <div className='paging-info'>
      <strong>
        {start} - {end}
      </strong>
    </div>
)


export default function SearchView({config,languageSelector,navigation}){
  return (<>
              <WithSearch mapContextToProps={({ wasSearched,error  }) => ({ wasSearched,error })}>
                {({ wasSearched, error }) => {

              if(error) {
                 error=(
                     <div className={"message"}>
                     <h4>
                       <p>We're on scheduled maintenance. Please be patient.</p>
                       <p>We will be back in a minute.</p>
                     </h4>
                       <img src={"./gifs/wait.gif"} /></div>)
              }
                  return (
                      <div className='App'>
                          {error}
                          <Layout
                              header={
                                <div>
                                  <config.vitrineHeader />
                                  <SearchBox
                                      autocompleteMinimumCharacters={3}
                                      autocompleteResults={{
                                        linkTarget: '_blank',
                                        sectionTitle: 'Results',
                                        titleField: 'title.pref.raw',
                                        urlField: 'handle',
                                        shouldTrackClickThrough: true
                                      }}
                                      autocompleteSuggestions={true}
                                      debounceLength={300}
                                      searchAsYouType={true}
                                      inputProps={{ placeholder: FieldLabels.searchBox[config.vitrineLang] }}
                                  />
                                </div>
                              }
                              sideContent={
                                <>
                                  <FilterBox />
                                  <FacetView />
                                </>
                              }
                              bodyContent={
                                <Results
                                    resultView={HitView}
                                    titleField='title'
                                    urlField='handle.url'
                                    thumbnailField='image_url'
                                    shouldTrackClickThrough={true}
                                />
                              }
                              bodyHeader={
                                <>
                                  {wasSearched && <PagingInfo view={CustomPagingInfoView} />}
                                  {<Paging />}
                                  {wasSearched && <ResultsPerPage />}
                                  {languageSelector}
                                  {navigation}
                                </>
                              }
                              bodyFooter={<Paging />}
                          />
                          <config.vitrineFooter></config.vitrineFooter>
                      </div>
                  )
                }}
              </WithSearch>
          </>
  );
}
