import React, { Fragment } from 'react'

/* eslint  no-console: 0 */
class VitrineHeader extends React.Component {
  render() {
    return (
      <Fragment>
        <div className='masthead' role='banner'>
          <div className='flex-container'>
            <div className='left'>
              <div className='logo-lef-large'>
                <a href='https://www.exc.uni-konstanz.de/ungleichheit/' className=''>
                  <img
                    src='https://www.exc.uni-konstanz.de/fileadmin/templates/relaunch/img/exc_ie.svg'
                    alt='The Politics of Inequality - Logo'
                    title='The Politics of Inequality'
                  />
                </a>
              </div>
            </div>

            <div className='right'>
              <div className='logo-large'>
                <a href='https://www.uni-konstanz.de/' className=''>
                  <img src='https://www.exc.uni-konstanz.de/fileadmin/templates/relaunch/img/logo.svg' alt='Universität Konstanz' />
                </a>
              </div>
            </div>
          </div>

          <div className='nav-container'>
            <ul className='nav-main' id='nav-main'>
              <li>
                <a href='https://www.exc.uni-konstanz.de/ungleichheit/ueber-uns/' className='more' data-title=''>
                  Über uns
                </a>
              </li>
              <li>
                <a href='https://www.exc.uni-konstanz.de/ungleichheit/news-und-events/' className='more' data-title=''>
                  News und Events
                </a>
              </li>
              <li className=''>
                <a href='https://www.exc.uni-konstanz.de/ungleichheit/themen/das-ungleichheitsbarometer/' className='more' data-title=''>
                  Themen
                </a>
              </li>
              <li className=''>
                <a href='https://www.exc.uni-konstanz.de/ungleichheit/forschung/' className='more' data-title=''>
                  Forschung
                </a>
              </li>
              <li className=''>
                <a href='https://www.exc.uni-konstanz.de/ungleichheit/unterstuetzende-strukturen/' className='more' data-title=''>
                  Unterstützende Strukturen
                </a>
              </li>
            </ul>
            <nav className='breadcrumb' aria-label='Sie befinden sich hier'>
              <a href='https://www.uni-konstanz.de/universitaet/ueber-die-universitaet-konstanz/exzellenzinitiative-und-strategie/exzellenzstrategie-seit-2019/'>
                exc.uni-konstanz.de
              </a>
              <span className='icon-point-right' aria-hidden='true'>
                {' '}
                &gt;
              </span>
              <a href='/search'>The Politics of Inequality: Research Data</a>
            </nav>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default VitrineHeader
